.dash-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex-grow: 1;
}

.dash-container .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dash-container .title h1 {
    margin: 10px 0;
    font-size: 2rem;
    color: var(--font-color);
}

.dash-container .emails {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    flex-grow: 1;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.dash-container .emails .header {
    display: flex;
    padding: 25px;
    justify-content: space-between;
    background-color: var(--primary-color);
    box-shadow: 0 0 6px black;
}

.dash-container .title button .MuiButton-label {
    font-size: 1rem;
}

.MuiPaper-elevation1 {
    background-color: var(--background-color) !important
}

.MuiIconButton-root {
    color: var(--font-color) !important;
}

.body {
    margin-top: 20px;
}

.body h1 {
    color: var(--font-color);
}

.address {
    display: flex;
    flex-direction: column;
    margin: 5px;
}

.address .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background-color: var(--primary-color);
    box-shadow: 0 0 4px black;
}

.address .title h3 {
    color: var(--font-color);
    margin: 0 25px;
}

.address .title div {
    display: flex;
    align-items: center;
}

.address .email-list {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    margin-top: 7px;
    transition: height 1s;
}

.address .email-list.open {
    display: flex;
    height: auto;
}

.email {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary-color);
    box-shadow: 0 0 4px black;
    cursor: pointer;
    padding: 15px;
    margin: 5px 0;
    flex-grow: 1;
}
 .email div {
    display: flex;
}

.email h3 {
    color: var(--font-color);
    margin: 0 25px;
}

.email div h3 span {
    margin: 0 20px;
}

.email-view {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    max-height: 600px;
}

.email-view .title {
    display: flex;
    flex-direction: column;
}

.email-view h3 {
    margin: 5px 0;
    margin-right: 15px;
    color: var(--font-color)
}

.email-view span {
    margin: 0 20px;
}

.email-view .email-port {
    margin-top: 15px;
    background-color: white;
    padding: 25px;
    border: 1px solid var(--font-color);
    overflow: scroll;
    max-width: 700px;
}

.MuiInputLabel-formControl {
    color: var(--font-color) !important
}

.MuiInput-underline:before {
    border-bottom: 1px solid var(--font-color) !important
}

#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}