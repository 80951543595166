body {
    /*overflow-y: hidden;*/
    background-color: var(--background-color);
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    
}

.login-container img {
    text-align: center;
    font-size: 5rem;
    margin-bottom: 150px;
    border-radius: 25px;
    width: 400px;

}

.login-choice {
    width: 100%;
    display: flex;
}

.login-choice div {
    width: 50%;
    padding: 10px;
    font-size: 1.5rem;
    border: none;
    outline: none;
    padding: 10px 0;
    background-color: #4359e7b3;
    color: white;
    text-align: center;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.7s, border-color 0.7s;
}

.login-choice div:hover {
    background-color: #303f9f;
    border-color: #4359e7;
}

.login-choice div[left="true"] {
    border-top-left-radius: 15px;
    border-right: 1px solid transparent;
}
.login-choice div[left="true"]:hover {
    border-right: 1px solid #4359e7;
}

.login-choice div[left="false"] {
    border-top-right-radius: 15px;
    border-left: 1px solid transparent;
}
.login-choice div[left="false"]:hover {
    border-left: 1px solid #4359e7;
}

.login-choice div.active {
    background-color: #3f51b5 !important;
}

.info {
    border-radius: 15px;
    width: 500px;
    height: auto;
    transition: height 0.3s;
    box-shadow: 0 0 16px black;
    background-color: var(--primary-color);
}

.info.isLogin {
    height: 325px;
}

.info.isSignup {
    height: 400px;
}

.info form {
    display: flex;
    flex-direction: column;
    padding: 25px;
    border-top: none;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.info form span {
    font-size: 1.2rem;
    text-transform: none;
}

.MuiFormControl-root {
    margin: 5px 0 !important;
}

.MuiButton-root {
    margin-top: 10px !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: var(--input-color) !important;
}

.MuiInputBase-input {
    color: var(--input-color) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: var(--input-color) !important;
}

.MuiInputLabel-outlined {
    color: var(--input-color) !important;
}