.navigation {
    position: sticky;
    top: 0;
    background-color: var(--primary-color);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0 1px 5px black;
    z-index: 100000000;
}

.navigation a {
    text-decoration: none;
    margin-left: 15px;
    font-size: 1.75rem;
    border: 1px solid transparent;
    padding: 2px 15px;
    transition: border-radius 0.3s, border-color 0.3s;
    color: var(--font-color);
}

.navigation a:hover {
    border: 1px solid var(--font-color);
    border-radius: 5px;
}

.navigation button {
    margin-right: 15px;
}

.navigation img {
    font-size: 2.5rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin: 0;
    color: var(--font-color);
    border-radius: 25px;
    visibility: hidden;
    height: 50px;
}

.navigation img.active {
    left: 0;
    transform: translateX(0);
    margin-left: 15px;
    visibility: visible;
}

.opacity-style {
    margin-top: 12px !important;
    margin-right: 25px !important;
    cursor: pointer;
    color: var(--font-color);
}