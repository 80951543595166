.BtnGroup {
    -webkit-text-size-adjust: 100%;
    --border-width: 1px;
    --border-style: solid;
    --font-size-small: 12px;
    --font-weight-semibold: 500;
    --size-2: 20px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    word-wrap: break-word;
    color: var(--font-color);
    line-height: 1.25;
    list-style: none!important;
    list-style-type: none;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
}

h3.address {
    -webkit-text-size-adjust: 100%;
    --border-width: 1px;
    --border-style: solid;
    --font-size-small: 12px;
    --font-weight-semibold: 500;
    --size-2: 20px;
    margin: 0px !important;
    word-wrap: break-word;
    list-style: none!important;
    list-style-type: none;
    box-sizing: border-box;
    text-decoration: none;
    display: inline-block;
    padding: 5px 16px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    border: 1px solid;
    appearance: none;
    background-color: var(--background-color);
    border-color: var(--primary-color);
    box-shadow: var(--color-btn-shadow),var(--color-btn-inset-shadow);
    transition: .2s cubic-bezier(.3,0,.5,1);
    transition-property: color,background-color,border-color;
    color: var(--font-color);
    position: relative;
    float: left;
    border-right-width: 0;
    border-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

clipboard-copy {
    -webkit-text-size-adjust: 100%;
    --border-width: 1px;
    --border-style: solid;
    --font-size-small: 12px;
    --font-weight-semibold: 500;
    --size-2: 20px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    word-wrap: break-word;
    list-style: none!important;
    list-style-type: none;
    box-sizing: border-box;
    display: inline-block;
    padding: 5px 16px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid;
    appearance: none;
    background-color: var(--background-color);
    border-color: var(--primary-color);
    box-shadow: var(--color-btn-shadow),var(--color-btn-inset-shadow);
    transition: .2s cubic-bezier(.3,0,.5,1);
    transition-property: color,background-color,border-color;
    position: relative;
    float: left;
    border-radius: 0;
    border-right-width: 1px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    fill: var(--font-color);
}

clipboard-copy:hover {
    border-color: #3f51b5
}